import React, {useEffect, useState} from "react";
import ReactTwitchEmbedVideo from "react-twitch-embed-video";
import styles from "./Match.module.scss";
import classNames from "classnames";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import * as StatisticsService from "../../services/StatisticsService";
import Scorecard from "../../components/Scorecard/Scorecard";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import {connect} from "react-redux";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import MatchScoreHeader from "./MatchScoreHeader";
import ScheduledMatchSection from "../ScheduledMatch/ScheduledMatchSection";
import {toast} from "react-toastify";
import * as ScheduleService from "../../services/SchedulerService"
import * as actions from "../../store/actions";
import {getConf} from "../../services/ConfigService";
import {MATCH_PAGE} from "../../utilities/ConfigConstants";
import PubNubHandler from "../../services/PubNubHandlers/MatchDataHandler";
import * as PubNubService from "../../services/PubNubService";
import {formatDate, formatTime} from "../../helpers/CommonFunctions";
import {useTranslation} from "react-i18next";
import YouTubeStream from "../../components/YouTubeStream/YouTubeStream";
import StatsNavigate from "../../components/StatsNavigate/StatsNavigate";
import MatchPauseConfirmationModal from "./MatchPauseConfirmationModal";

const Match = (props) => {

   const location = useLocation();
   let {matchId} = useParams();
   let navigate = useNavigate();
   const returnUrl = new URLSearchParams(location.search).get('returnUrl');

   const [matchData, setMatchData] = useState({});
   let [loading, setLoading] = useState(true);
   const [screenSize, setScreenSize] = useState(window.innerWidth);
   const uuid = localStorage.getItem("uuid");
   const [showVideoStream, setShowVideoStream] = useState(false);
   const [connectPubnub, setConnectPubnub] = useState(false);
   const [token, setToken] = useState(null);
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [isMatchAvailable, setIsMatchAvailable] = useState(true);
   const [isMatchPaused, setIsMatchPaused] = useState(false);
   const [showMatchPauseConfirmation, setShowMatchPauseConfirmation] = useState(false);

   // To show and hide video stream
   // const handleVideoStreamShowAndHide = (state) => {
   //    const STREAM_SWITCH_ON_TOAST = "STREAM_SWITCH_ON_TOAST";
   //    const STREAM_SWITCH_OFF_TOAST = "STREAM_SWITCH_OFF_TOAST";
   //
   //    if (state === true) {
   //       toast.loading(t("MATCH.SWITCHING_STREAM_ON"), {
   //          toastId: STREAM_SWITCH_ON_TOAST
   //       })
   //    } else {
   //       toast.loading(t("MATCH.SWITCHING_STREAM_OFF"), {
   //          toastId: STREAM_SWITCH_OFF_TOAST
   //       })
   //    }
   //
   //    setTimeout(() => {
   //       setShowVideoStream(state)
   //       toast.dismiss(STREAM_SWITCH_ON_TOAST)
   //       toast.dismiss(STREAM_SWITCH_OFF_TOAST)
   //    }, 1000)
   // }

   const handleReturn = () => {
      if (returnUrl) {
         navigate(returnUrl, {state: location.search});
      } else {
         navigate('/matches')
      }
   };

   useEffect(() => {
      if (!props.Token) {
         navigate(`/login`);
      }
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, [props.Token]);

   const handleNextMatch = async () => {
      const toastId = toast.loading(`${t("MATCH.GETTING_NEXT_MATCH_DETAILS")}`)
      await ScheduleService.getNextMatch(matchId, props.tenantId).then((response) => {
         if (response.apiStatus === true) {

            toast.update(toastId, {
               render: `${t("MATCH.MATCH_AVAILABLE_REDIRECTING")}`,
               type: toast.TYPE.SUCCESS,
               autoClose: 5000,
               closeButton: null,
               isLoading: false
            })


            const newUrl = `/match/${response.id}` + `?returnUrl=${encodeURIComponent(location.pathname)}`;

            setTimeout(() => {
               window.location.href = newUrl;
            }, 1000);

         } else {
            toast.update(toastId, {
               render: `${t("MATCH.NO_MATCHES_SCHEDULED_YET")}`,
               type: toast.TYPE.ERROR,
               autoClose: 5000,
               closeButton: null,
               isLoading: false
            })
         }
      });
   };

   useEffect(() => {
      const fetchData = async () => {
         if (matchId === "undefined") {
            return;
         }

         try {
            PubNubService.requestAccessTokenMatch(props.userName || localStorage.getItem("uuid"), matchId, props.Token).then(response => {
               if (response != null) {
                  setToken(response);
                  setConnectPubnub(true);
                  localStorage.setItem("pubnubMatchToken", response);
               }
            });
         } catch (error) {
            console.error("Error:", error);
         }
      };
      fetchData();
   }, [matchId, props.Token, props.userName, uuid])

   useEffect(() => {
      const getMatchStats = async () => {
         setLoading(true);
         let isError = false;
         if (matchId === "undefined") {
            isError = true;
         } else {
            await StatisticsService.getMatchStat(matchId, props.Token).then((response) => {
               if (response.data.apiStatus) {
                  setMatchData(response.data.match);
                  setIsMatchAvailable(true);
               } else if (response.status === 400) {
                  isError = true;
               }
            });
         }

         if (isError) {
            setIsMatchAvailable(false);
            toast.error(`No Match Available.`);
         }

         setLoading(false);
      };

      getMatchStats();
   }, [matchId, props.Token]);

   // update screen size
   useEffect(() => {
      const handleResize = () => {
         setScreenSize(window.innerWidth);
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   }, []);

   const loadingIndicator = (<div className="w-100 h-100 text-center">
      <div className={styles.loaderContainer} style={{transform: "translateY(0%)"}}>
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   </div>);

   if (!translationsLoaded) {
      return;
   }

   /**global leader board config change end*/
   const messageFromCallback = (message) => {
      try {
         let json = JSON.parse(message);
         setMatchData(prevState => ({
            ...json.body,
            teamOne: prevState.teamOne,
            teamTwo: prevState.teamTwo,
            tournament: json.body.tournament || prevState.tournament,
            matchStartTime: prevState?.matchStartTime,
         }));


      } catch (error) {
         console.error("JSON parsing error:", error);
      }
   }

   return (
      <div>
         {connectPubnub && props.isAuthenticated && props.userName ?
            <PubNubHandler onDataReceived={messageFromCallback} token={token} matchId={matchId}/> : <></>}

         <div className="pb-4 min-vh-100 d-flex flex-column justify-content-between">
            <div className="col-12">
               <div className={classNames(styles.leftGradient)}></div>
               <div className={classNames(styles.rightGradient)}></div>
               <div
                  className={classNames(styles.ResponceCont, "col-12", "row", "ps-1", "pe-1", "ps-xxl-0", "pe-xxl-0")}>
                  <div className="container p-0">
                     <div className="row gx-0">
                        <div className="col-12">
                           <NavbarSecondary page="events"/>
                        </div>
                     </div>
                     {(!translationsLoaded || loading) ? <> {loadingIndicator} </> : <div className="row gx-0">
                        <div
                           className={classNames(styles.headerBtnWrapper, "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 col-xxl-9")}>
                           <button onClick={() => handleReturn()} className="blackBtn mobile-hide-">
                              <BsChevronLeft/>{t("MATCH.BACK")}
                           </button>

                           {/*<div className={classNames(styles.text14OS, "pt-3", "pb-1", "p-3")}>NOTE :Winning Probability is still under testing</div>*/}
                           {/* =========== Toggle Video Button ============/}
                           {/*<div className={classNames(styles.StreamSwitch)}>*/}
                           {/*   <input type="checkbox" id="toggle-switch" checked={showVideoStream && matchData?.live}*/}
                           {/*          disabled={!matchData?.live}*/}
                           {/*          onChange={() => handleVideoStreamShowAndHide(!showVideoStream)}/>*/}
                           {/*   <label htmlFor="toggle-switch"*/}
                           {/*          style={{cursor: matchData?.live ? "pointer" : "not-allowed"}}>*/}
                           {/*      <span className={classNames(styles.icon1)}/>*/}
                           {/*      <span className={classNames(styles.icon2)}/>*/}
                           {/*   </label>*/}
                           {/*</div>*/}

                           {isMatchAvailable ? <button onClick={() => handleNextMatch()}
                                                       className="blackBtn d-none- d-sm-block ml-auto"
                                                       style={{float: "right"}}>
                                 {t("MATCH.NEXT_MATCH")}<BsChevronRight style={{left: 12}}/>
                              </button> :
                              <Link to={'/latest'} className="blackBtn d-none- d-sm-block text-decoration-none ml-2"
                                    style={{float: "right"}}>
                                 Go to latest match<BsChevronRight style={{left: 12}}/>
                              </Link>}
                        </div>

                        {isMatchAvailable && <>
                           <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 col-xxl-9">
                              {matchData?.toStart === true || (matchData?.live === false && matchData?.matchEnd === false && matchData?.winningTeamId === null) ?
                                 <ScheduledMatchSection tossWinnerId={matchData?.tossWinnerId}
                                                        matchData={matchData}
                                                        matchId={matchId}
                                                        isAuthenticated={props.isAuthenticated} teamOne
                                 /> : <></>}

                              {matchData?.toStart !== true ? <>
                                 {loading ? loadingIndicator : (
                                    <MatchScoreHeader matchData={matchData} token={props.Token}/>)}
                                 {matchData?.live === true && showVideoStream ?
                                    <div className="row gx-0" style={{marginTop: "20px"}}>
                                       <div className="col-12 mb-4">
                                          {getConf(MATCH_PAGE.YOUTUBE_VIDEO_ID) ?
                                             <YouTubeStream videoId={getConf(MATCH_PAGE.YOUTUBE_VIDEO_ID)}/> :
                                             <ReactTwitchEmbedVideo
                                                width={"100%"}
                                                height={screenSize < 1200 ? 200 : 400}
                                                layout={"video"}
                                                // eslint-disable-next-line no-undef
                                                channel={process.env.REACT_APP_TWITCH_CHANNEL_NAME}
                                             />}
                                       </div>
                                    </div> : <></>}
                              </> : <>
                              </>}
                              <div className={classNames(styles.ScoreBoardRow, "row gx-0 ScoreBoardRow")}>
                                 <div className="col-12">
                                    {/* eslint-disable-next-line no-prototype-builtins */}
                                    {(matchData?.hasOwnProperty("matchInningList") || matchData?.matchInningList) ?
                                       <div className="row gx-0 mt-2">
                                          <Scorecard matchData={matchData}/>
                                       </div> : <div className="row gx-0">
                                          {/*<PreMatchScheduleCard matchData={matchData}/>*/}
                                       </div>}
                                 </div>
                              </div>
                           </div>
                           <div className={classNames(styles.QbotCol, "col-12 col-xl-3 col-xxl-3 ps-0 ps-xl-4 ")}>
                              {matchData?.live === true &&
                                 <button onClick={() => setShowMatchPauseConfirmation(true)} className="blackBtn d-none- d-sm-block m-auto text-danger mb-4 w-100"
                                 ><b>{isMatchPaused ? 'Resume' : 'Pause'} the match</b>
                                 </button>
                              }
                              <div
                                 className={classNames(styles.questWrap, 'mb-4', "col-12 col-md-6", "col-xl-12", "p-0", "d-none d-xl-block")}
                                 style={{position: "relative"}}>
                                 <StatsNavigate/>
                              </div>

                              <div className="row p-0">
                                 {/* OVERVIEW */}
                                 <div className="col-12 row g-0 col-xl-12 ps-0 ps-xl-0 p-0">
                                    <div className="col-12">
                                       <div
                                          className={classNames(styles.NotitWrap, "p-0", "pt-1", "pb-1", " mb-4")}>
                                          <div className={classNames(styles.Text24Solina, "p-3")} style={{
                                             borderBottom: "1px solid #FFFFFF0F", border: "1px solid #FFFFFF0F"
                                          }}>{t("MATCH.OVERVIEW")} </div>
                                          <div className={classNames(styles.text14OS, "pt-3", "pb-1", "p-3")}
                                               style={{lineHeight: "20px"}}>
                                             Start:
                                             <br/>
                                             <div className={classNames(styles.text14OSW, "pt-2", "pb-2")}>
                                                <img
                                                   src="https://storage.googleapis.com/meta11/serviceplatform/fi_calendar.webp"></img>
                                                &nbsp;{formatDate(matchData?.matchStartTime) || '--'}&nbsp;at&nbsp;{formatTime(matchData?.matchStartTime) || '--'}
                                             </div>
                                             {matchData?.toStart === true && !matchData?.tossWinnerId && <>
                                                {t("MATCH.GET_READY_FOR_AI_POWERED_CRICKET_ACTION_ON_META_11")} {t("MATCH.IT_S")} {" "}
                                                {matchData?.teamOne?.name || " "} vs {matchData?.teamTwo?.name || " "}&nbsp;
                                                {t("MATCH.IN_A_FANTASY_MATCH_THAT_PROMISES_EXCITEMENT_BEYOND_BOUNDARIES")}
                                             </>}
                                          </div>
                                          {matchData?.tossWinnerId &&
                                             <div className={classNames(styles.text14OS,"pb-3", "p-3")}
                                                  style={{lineHeight: "20px"}}>
                                                Toss:
                                                <br/>
                                                <div className={classNames(styles.text14OSW, "pt-2", "pb-2")}>
                                                   <img
                                                      src="https://storage.googleapis.com/meta11/serviceplatform/coinFlipping.png"></img>
                                                   &nbsp;{`${matchData?.tossWinnerName} won the toss and chose to bat first`}
                                                </div>
                                             </div>
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              {((matchData?.toStart === true) || !getConf(MATCH_PAGE.SHOW_META_11_MATCH_PAGE)) ? <></> :
                                 <div className="row gx-0">
                                    <div className="col-12 mb-lg-4 d-none d-xl-block mb-0 mb-sm-0 mb-md-0 ">
                                       <div className="adImgWrap">
                                          <img
                                             src="https://storage.googleapis.com/meta11/serviceplatform/StaticBanner1.webp"
                                             alt=""/>
                                       </div>
                                    </div>
                                 </div>}
                           </div>
                        </>}
                     </div>}
                  </div>

               </div>
            </div>

            <div className={classNames(isMatchAvailable ? '' : 'fixed-bottom')}>
               {!translationsLoaded ? <> </> : <>
                  <Footer/>
               </>}
            </div>

         </div>
         {showMatchPauseConfirmation &&
            <MatchPauseConfirmationModal
               onCloseModal={() => setShowMatchPauseConfirmation(false)}
               matchId={matchId}
               isPaused={isMatchPaused}
               setIsPaused={setIsMatchPaused}
            />}
      </div>);
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken != null || undefined,
      userId: state.auth.userId,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      refresh: state.auth.refreshToken,
      tenantId: state.auth.tenantId
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()),
      onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};
export default connect(mapStateToProps, mapDispatchToProps)(Match);
