import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import styles from "../Home/Home.module.scss";
import classNames from "classnames";
import * as StatisticService from "../../services/StatisticsService";
import {getConf} from "../../services/ConfigService";
import {MATCH_PAGE} from "../../utilities/ConfigConstants";

const Latest = (props) => {
   let navigate = useNavigate();
   const [lang] = useState(localStorage.getItem("language"));
   const TOAST_ID = "TOAST";

   const toastUpdate = (render, type) => {
      toast.update(TOAST_ID, {
         render: render, type: type, autoClose: 5000, closeButton: null, isLoading: false
      })
   }

   const handleTournamentAvailable = useCallback(async (latestMatch) => {
      setTimeout(() => {
         navigate(`/match/${latestMatch?.matchId}`);
      }, 1000);
   }, [navigate])

   useEffect(() => {
      const navigateToLatestMatch = async () => {
         let latestMatch = {};
         toast.loading(`Checking for the latest tournament..`, {
            toastId: TOAST_ID
         });

         if (!props.token) {
            toastUpdate('Please Login.', toast.TYPE.INFO)

            setTimeout(() => {
               navigate(`/login`);
            }, 1000);
            return;
         }

         await StatisticService.getAllMatches(lang, props.token, 0, 1, 'isLive,isScheduled,matchId,desc', props.tenantId).then((response) => {
            if (response?.apiStatus && response.totalElements !== 0) {
               latestMatch = response.matches?.[0];
            } else {
               toastUpdate(`Error occurred in taking you to the latest match. Please try again later..`, toast.TYPE.ERROR)
            }
         })

         toastUpdate(`Taking to the latest Match..`, toast.TYPE.SUCCESS)
         await handleTournamentAvailable(latestMatch);
      }

      navigateToLatestMatch();
   }, [handleTournamentAvailable, lang, navigate, props.token, props.userName]);

   return (<div className={classNames(styles.homeContainer)}>
      <div className="w-100 text-center">
         <div className={styles.loaderContainer}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>
   </div>);
};

const mapStateToProps = (state) => {
   return {
      token: state.auth.accessToken,
      tenantId: state.auth.tenantId
   };
};

export default connect(mapStateToProps)(Latest);
