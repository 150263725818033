import React, {useEffect, useState} from "react";
import Styles from "./Login.module.scss"
import Classnames from "classnames"
import {Link, useNavigate} from "react-router-dom";
import * as UserService from "../../services/UserService";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {AUTHENTICATION_TOASTS} from "../../utilities/ToastMessages";
import {decryptData, encryptData} from "../../helpers/SecureLocalStorage";
import chopraImgMain from "../../assets/images/chopraImg.webp";
import {useTranslation} from "react-i18next";
import {getConf} from "../../services/ConfigService";
import {LOGIN_PAGE} from "../../utilities/ConfigConstants";
import ResetPasswordSender2 from "./ResetPasswordSenderModal";

const Login = (props) => {
   let navigate = useNavigate();
   const [userName, setUserName] = useState("");
   const [password, setPassword] = useState("");
   const [userNameValid, setUserNameValid] = useState(true)
   let [passwordValid, setPasswordValid] = useState(true)
   const [loginError, setLoginError] = useState("");
   const [showPassword, setShowPassword] = useState(false);
   const [disableFromValidation, setDisableFromValidation] = useState(true);
   const [errorOutline, setErrorOutline] = useState(false)
   const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe'));
   const [lang] = useState(localStorage.getItem("language"));
   const [modalOpen, setModalOpen] = useState(false);

   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   const handleModalClose = (childResponse) => {
      setModalOpen(childResponse)
   }

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   const loadingIndicator = (<div className="w-100 text-center">
      <div style={{
         position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
      }}>
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   </div>);

   useEffect(() => {

      if (localStorage.getItem('accessToken') != null) {
         navigate("/latest");
      }

      setUserNameValid(true);
      setPasswordValid(true);

      //remember me configuration
      const isPreviouslyLogged = localStorage.getItem('previouslyLogged');
      const rememberMeToken = decryptData(localStorage.getItem('rememberMeToken'));
      if (isPreviouslyLogged && rememberMe && rememberMeToken) {
         const credArr = rememberMeToken?.split('-');
         setUserName(credArr[0]);
         setPassword(credArr[1]);
      }
   }, [])

   useEffect(() => {
      setLoginError("");
      if (userName && password) {
         setDisableFromValidation(false);
      }
   }, [userName, password])

   const handleUserNameChange = (e) => {
      // setRememberMe(false);
      // setErrorOutline(false)
      // setUserNameError("")
      // const emailValid = e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      // setUserNameValid(emailValid !== null)
      // setDisableFromValidation(emailValid == null)
      setUserName(e.target.value)
   }
   const handlePasswordChange = (e) => {
      setRememberMe(false);
      setErrorOutline(false)
      setPasswordValid(true);
      passwordChecker(e.target.value)
      setDisableFromValidation(false)
      setPassword(e.target.value)
   }
   useEffect(() => {
      if (userName === "" || password === "") {
         setDisableFromValidation(true)
      }
   }, [userName, password])
   const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
   };

   useEffect(() => {
      localStorage.setItem('rememberMe', rememberMe);
      if (rememberMe && userNameValid && userName && password) {
         const rememberMeToken = `${userName}-${password}`;
         localStorage.setItem('rememberMeToken', encryptData(rememberMeToken))
      } else if (!rememberMe) {
         localStorage.removeItem('rememberMeToken')
      }
   }, [rememberMe]);

   const passwordChecker = (password) => {
      if (password === null) {
         setLoginError("")
         setPasswordValid(false)
         setDisableFromValidation(true)
      } else if (password === "") {
         setPasswordValid(false)
         setDisableFromValidation(true)
      } else {
         setLoginError("");
         setPasswordValid(true)
         setDisableFromValidation(false)
      }
   }

   if (!translationsLoaded) {
      return loadingIndicator;
   }

   const loginUser = (tenantId) => {

      if (passwordValid && userNameValid) {
         const toastId = toast.loading(t("LOGIN_PAGE.LOGGING_IN"));
         UserService.loginUser({
            "password": password, "userName": userName
         }).then((response) => {
            if (response.loginStatus === true) {
               props.onAuth(
                  response?.token?.tokenValue,
                  "email",
                  response?.adminDto?.role,
                  response?.adminDto?.userName,
                  (response?.adminDto?.firstName + " " + response?.adminDto?.lastName),
                  response?.adminDto?.email,
                  tenantId
               );
               localStorage.setItem("previouslyLogged", !response?.newUser);

               toast.update(toastId, {
                  render: t("LOGIN_PAGE.WELCOME_BACK_TO_M_11_OMNIVERSE"),
                  type: toast.TYPE.SUCCESS,
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })

               navigate('/latest')

            } else {
               toast.update(toastId, {
                  render: t("LOGIN_PAGE.SORRY_LOGIN_FAILED_PLEASE_TRY_AGAIN"),
                  type: toast.TYPE.ERROR,
                  autoClose: 5000,
                  closeButton: null,
                  isLoading: false
               })

               if (lang === "hi") {
                  if (response?.data?.message === "USER_NOT_AVAILABLE") {
                     setLoginError("ईमेल या पासवर्ड ग़लत है। अगर आपके पास खाता नहीं है,");
                  } else if (response?.data?.message === "INCORRECT_USERNAME_OR_PASSWORD") {
                     setLoginError("गलत उपयोगकर्ता नाम या पासवर्ड");
                  } else if (response?.data?.message === "CREDENTIAL_MISMATCH") {
                     setLoginError("क्रेडेंशियल बेमेल");
                  }
               } else {
                  if (response?.data?.message === "USER_NOT_AVAILABLE") {
                     setLoginError("Email or Password incorrect. If you dont have an account,");
                  } else if (response?.data?.message === "INCORRECT_USERNAME_OR_PASSWORD") {
                     setLoginError("Incorrect Username or Password");
                  } else if (response?.data?.message === "CREDENTIAL_MISMATCH") {
                     setLoginError("Credential Mismatch");
                  }
               }

               setErrorOutline(true)
               setDisableFromValidation(false)
            }
         }).catch((e) => {
            console.error("Error11:", e);
            toast.error(AUTHENTICATION_TOASTS.ERROR_OCCURRED_WHILE_LOGIN)
         })
      }
   }

   const handleRememberMe = (event) => {
      setRememberMe(event.target.checked)
   }

   const handleResetPassword = () => {
      // toast.info("This feature is under development", {
      //    toastId: "UNDER_DEVELOPMENT"
      // })
      setModalOpen(true);
   }

   return (<div className={Classnames(Styles.outterWrap)}>
      <div className={Classnames("row", Styles.midContent, "pt-0 pt-sm-0 pt-md-0 pt-lg-5", "pb-2")}>
         <div className={Classnames(Styles.Radial1)}></div>
         <div className={Classnames(Styles.Radial2)}></div>

         <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5">
            {getConf(LOGIN_PAGE.SHOW_META_11_LOGIN) && <Link to={"/"}>
               <img src="https://storage.googleapis.com/meta11/serviceplatform/M11FantasyBetaLogo.webp" alt=""
                    className={Classnames(Styles.loginLogo, Styles.marginTopMd)}
                    style={{width: '197px', height: '54px'}}/>
            </Link>}

            <div className={Classnames(Styles.FormWrapper)}>


               <div className={Classnames(Styles.TopBotGaps)}>
                  <div className="col-12 p-0 d-flex">
                     <div className={Classnames(Styles.TopBotGaps, "mt-5")}>
                        <div className="heading2 gradient-color">{t("LOGIN_PAGE.WELCOME")}</div>
                     </div>
                  </div>

                  <div className={Classnames(Styles.FormBox, "justify-content-start")}>
                     <div className={Classnames(Styles.group, Styles.group1)}>
                        <input type="text" name="name" required value={userName}
                               style={{maxWidth: "none", width: "100%"}}
                               onChange={handleUserNameChange}
                               className={!userNameValid || errorOutline ? Classnames(Styles.Error) : ""}/>
                        <label>{t("LOGIN_PAGE.USERNAME")}</label>
                     </div>
                     <div className={Classnames(Styles.group)}>
                        <input type={showPassword ? 'text' : 'password'} name="name" required value={password}
                               onChange={handlePasswordChange}
                               className={Classnames(!passwordValid || errorOutline ? Classnames(Styles.Error) : "", showPassword ? "" : Styles.asteriskInput)}/>
                        <label>{t("LOGIN_PAGE.PASSWORD")}</label>
                        <span className={!showPassword ? Styles.normalEye : Styles.slashEye}
                              onClick={togglePasswordVisibility}></span>
                     </div>
                     <div className="mb-2 mb-sm-0 mb-md-0 mb-lg-4 p-0 ps-0 mt-3 align-middle row p-0 col-12"
                          style={{color: '#95A6B7'}}>
                        <div className="col-1 p-0 float-start"><input type="checkbox"
                                                                      className={Classnames(Styles.FormCheckInput, "form-check-input")}
                                                                      checked={rememberMe}
                                                                      defaultValue={false}
                                                                      disabled={!userName || !password || !userNameValid}
                                                                      onChange={handleRememberMe}
                                                                      id="exampleCheck1" style={{margin: "0"}}/>
                        </div>
                        <div className="col-11 p-0 float-start body-text4 color-gray2"
                             style={{lineHeight: '18px'}}>{t("LOGIN_PAGE.REMEMBER_ME")}
                           <input value={t("LOGIN_PAGE.FORGOT_PASSWORD?")} type={"button"} className="float-end"
                                  style={{color: '#ffffff', background: 'none', border: '0px'}} onClick={handleResetPassword}/>
                        </div>
                     </div>

                     {loginError ? <text className={Classnames(Styles.TextError)}>{loginError}</text> : ''}

                     <input onClick={() => loginUser(1)} type="submit"
                            disabled={!passwordValid || !userNameValid || disableFromValidation}
                            className={Classnames(Styles.LoginButton, (!passwordValid || !userNameValid || disableFromValidation) ? "" : Styles.buttonColor)}
                            value={"Login to Stream 01"}></input>
                     <input onClick={() => loginUser(2)} type="submit"
                            disabled={!passwordValid || !userNameValid || disableFromValidation}
                            className={Classnames(Styles.LoginButton, "ml-2", (!passwordValid || !userNameValid || disableFromValidation) ? "" : Styles.buttonColor)}
                            value={"Login to Stream 02"}></input>
                  </div>
               </div>
            </div>
         </div>
         {getConf(LOGIN_PAGE.SHOW_META_11_LOGIN) &&
            <div
               className={Classnames("neonLight col-12 col-sm-12 col-md-12 col-lg-6 col-xl-7 d-none d-lg-flex align-items-center justify-content-center")}>
               <img src={chopraImgMain}
                    className={Classnames(Styles.imgSize, "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10")}/>
            </div>
         }
         {
            modalOpen ? <ResetPasswordSender2 onCloseModal={handleModalClose}/> : ""
         }
      </div>
   </div>);
};

const mapStateToProps = state => {
   return {
      isAuthenticated: state.auth.accessToken !== null || undefined,

   }
}

const mapDispatchToProps = dispatch => {
   return {
      onAuth: (accessToken, oAuthProviderType, userRole, userName, name, email, tenantId) => dispatch(actions.auth(accessToken, null, oAuthProviderType, userRole, userName, name, email, tenantId))
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
